import './App.css';
import React from 'react';
import ComponentLineChart from  './LineChart';

function App() {
  return (
    <div class="" className="App">
       <div class="py-5 bg-gray-100">
            <nav class="flex justify-center">
                <ul class="flex flex-row">
                    <li class="pr-5">
                      <a href="https://github.com/andrewcuriano" target="_blank" rel="noopener noreferrer"><img class="h-5 hover:scale-150" src="/github.svg" alt="github"/> </a>
                    </li>
                    <li class="pr-5">
                      <a href="https://www.linkedin.com/in/andrewcuriano" target="_blank" rel="noopener noreferrer"><img class="h-5" src="/linkedin.svg" alt="LinkedIn"/> </a>
                    </li>
                    <li class="">
                      <a href="mailto:andrewcuriano@protonmail.com" target="_blank" rel="noopener noreferrer" ><img class="h-5" src="/paperplane.svg" alt="email"/> </a>
                    </li>
                </ul>
            </nav>
      </div>
      <div class="flex px-2 py-20 bg-gray-900 ">
        <div class="max-w-prose m-auto">
          <p class="text-left font-mono text-white">
            <span class="text-green-400">localhost</span>
            <span class="text-purple-400"> ~ $ </span>
            <span class="font-mono text-white">whois andrewcuriano</span>
          </p>
          <p class="text-left pl-10 font-mono text-white">
            <p>cybersecurity student</p>
            <p>software developer</p>
            <p>music producer</p>
          </p>
        </div>
      </div>
        
      <div class="p-5"></div>
      <div class="bg-white-200">
        <p class="bg-white-200 font-sans text-4xl">
          Projects
        </p>
      </div>
      <div class="p-2">
        <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden lg:max-w-4xl md:max-w-2xl hover:bg-gray-100 hover:border-black">
          <div class="md:flex">
            <div class="md:flex-shrink-0">
              <img class="h-48 w-full object-cover md:w-48" src="/hydro.jpg" alt="veggies"/>
            </div>
            <div class="p-8">
              <a href="#hydroGraphs" class="block mt-1 text-lg leading-tight font-medium text-black hover:underline text-left">Automated Hydroponics</a>
              <p class="mt-2 text-gray-500 text-left">Hardware and software system utilizing sensors and robotics to monitor and regulate environmental conditions for growing hydroponic vegetables.</p>
            </div>
          </div>
        </div>
        <div class="p-5"></div>
        {/* end card 1 */}
        <div id="hydroGraphs" class="grid lg:grid-cols-2 md:grid-cols-1 gap-4 lg:max-w-5xl mx-auto">
          <ComponentLineChart title={"Electrical Conductivity"} graph={"ec"}/>
          <ComponentLineChart title={"pH"} graph={"pH"}/>
        </div>
      <div class="text-left max-w-prose mx-auto bg-white overflow-hidden lg:max-w-4xl md:max-w-2xl">
        <p class="text-center pb-5 text-2xl">
          More About This Project 
        </p>
        <p class="text-left pb-5 font-bold">
          Goal 
        </p>
        <p class="text-left pb-5">
        Create an automated hydroponics system in which environmental factors for optimal plant growth are regulated by machines with minimal manual intervention. Allowing for scalable production of fresh, healthy plants and herbs to be enjoyed by my friends and family.
        </p>
        <p class="text-left pb-5 font-bold">
          Details
        </p>
        <p class="text-left pb-5">
          A Raspberry Pi collects data from sensors connected via the GPIO pins and an I2C circuit. These are parsed in a python script and sent to a NodeJS server. Whenever the Node server receives new data it creates a document of that data in a MongoDB server. The Node server also serves a React application for the front end. When a client loads the website, a socket connection is made from the client to the node back end server. This socket connection is made within a React hook that handles the data in the graphs. When the Node server makes this socket connection with the client it will fetch the most recent records from the MongoDB cluster, this is then emitted to the client. Once the client receives the data, the graph component is re-rendered with the newest data. The graph should be re-rendering in real time whenever the Node server received data from the Raspberry Pi.
          
        </p>
        <p class="pb-5">
          Right now the current implementation for plotting the data uses <a class="text-blue-800 hover:underline" href="https://recharts.org/">recharts</a>, which imposes some limitations in 
          customizability of the charts. The plan is to implement future graphs with <a class="text-blue-800 hover:underline" href="https://d3js.org/">D3.js</a> which 
          will allow for much further customization and expanded data visualization 
          possibilities.
        </p>
        <p class="text-left pb-5 font-bold">
          Current Features 
        </p>
        <ul class="list-disc list-inside pb-5 pl-5">
          <li>Automated pH balancing and monitoring</li>
          <li>Automated nutrient dosing and electrical conductivity monitoring</li>
          <li>Automated light switching</li>
        </ul>
        <p class="text-left pb-5 font-bold">
          Planned Features 
        </p>
        <ul class="list-disc list-inside pb-5 pl-5">
          <li>Ability to manually tune environmental conditions remotely</li>
          <li>Visual representation of grow containers, allowing users to hover over plant nodes to display a tooltip with </li>
          <li>Set line chart time interval, allowing the user to specify the time domain of what data is shown</li>
          <li>Add camera connected to Raspberry Pi for time lapse photography of plants growing, as well as remote visual monitoring of plant health/development</li>
        </ul>
        
        
      </div>
      </div>
      <div class="bg-gray-100 p-10">
        <p class="font-sans text-md text-center">
          Designed & Built by Andrew Curiano
        </p>
      </div>
      
    </div>
  );
}

export default App;
