import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import io from "socket.io-client";
// const socket = io('https://andrewcuriano.com');


const ComponentLineChart = (props) => {
    const [response, setResponse] = useState("");
    let yAxis = ''
    let offSet = 0
    let chartDataKey = ''
    let dataMin = 0
    let dataMax = 0
    if (props.title === 'Electrical Conductivity') {
        yAxis = '\xB5S/cm'
        offSet = 40
        chartDataKey = 'Electrical Conductivity'
        dataMin = 1000
        dataMax = 3500
    }
    if (props.title === 'pH') {
        yAxis = 'pH'
        offSet = 70
        chartDataKey = 'pH'
        dataMin = 4
        dataMax = 8
    }
    
    useEffect(() => {
        // console.log('entered use effect')  
        const socket = io('https://andrewcuriano.com');
        document.title = "Andrew Curiano";
        // console.log(`socket is ${socket}`);
        socket.on(('FromAPI'), APIdata => {
            const graphDataArr = []
            APIdata.forEach(element => {
                const dateNum = (element.created_at*1000)
                const newDate = new Date(dateNum).toLocaleTimeString()
                const graphData = {
                    created_at: newDate,
                    "Electrical Conductivity": element.containerEC,
                    "pH": element.containerPH
                }
                graphDataArr.push(graphData)
            });

                

            console.log(graphDataArr)
            setResponse(graphDataArr);
            // socket.emit('ask')
            // console.log(APIdata);
        });
        //socket.off(('FromAPI'))
        return () => socket.disconnect();
    }, []);
    
    return (
    <div class="max-w-md mx-auto bg-white md:max-w-2xl py-10">
        <p class="block mt-1 text-lg leading-tight font-medium text-black text-center">{props.title}</p>
        <LineChart
            width={550}
            height={300}
            data={response}
            label="test"
            margin={{
                top: 15,
                right: 40,
                left: 20,
                bottom: 20,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={"created_at"} label={{ value: "Time", position: "insideBottomLeft", dy: 30}}/>
            <YAxis domain={[dataMin, dataMax]} label={{ value: yAxis, position: "insideLeft", angle: -90,   dy: offSet, dx: -10}}/>
            <Tooltip />
            <Legend />
            <Line type="number" dataKey={chartDataKey} stroke="#8884d8" strokeDasharray="15 5" />
        </LineChart>
    </div>
    )
};
  


export default ComponentLineChart;
